

























































































































































































































































































































































































































































































































































@import '../../assets/scss/core/variables.scss';
@import '../../assets/scss/core/breakpoints.scss';

/deep/ .aula_editor .tox.tox-tinymce {
  overflow: unset;

  .tox-editor-header {
    position: sticky;
    top: -1px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
  }

  .tox-sidebar-wrap {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
  }

  .tox-editor-container {
    overflow: unset;
  }
}
