




















































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/documents/_overview.scss';

.documents-secure-list-container {
  height: 100%;
  .aula-documentsSecureList-container {
    padding: 19px;
    background-color: $color-grey-light;
    height: 100%;
    overflow-y: auto;

    .document-picker-filter {
      --modal-inner-input-background-color: var(--color-white);
    }
  }
}

.table {
  position: relative;
  z-index: 100;
  label.table-row.body {
    text-transform: unset;
    font-weight: unset;
    &.disabled {
      background-color: var(--color-grey-darker);
    }
  }
}

.header > .table-cell {
  &.title {
    min-width: 120px;
  }
}

.table-cell.check {
  width: 20px;
  padding: 0 !important;
  .custom-checkbox {
    padding: 4px;
    margin-bottom: 0;
    left: 30px;
  }
}
.table-cell.actions {
  .custom-checkbox {
    margin-bottom: 20px;
    left: 8px;
  }
}

.upload-link {
  cursor: pointer;
  float: left;
  margin-top: 12px;
}
.submit-row {
  margin-top: 15px;
}
.icon-Aula_edit_pen {
  font-size: 20px;
  margin-right: 15px;
  cursor: pointer;
}
.icon-Aula_attach_doc {
  font-size: 20px;
  margin-right: 15px;
  margin-top: 5px;
  display: block;
}
.modalSharedWith {
  .row {
    .col,
    .col-1,
    .col-2,
    .col-4 {
      padding: 10px 10px 5px 10px;
      border-bottom: solid 1px #eee;
    }
    .text {
      padding-top: 13px;
    }
    .check {
      text-align: center;
      label {
        margin-right: 0;
      }
    }
    .delete {
      text-align: right;
      padding-top: 14px;
    }
  }
}
.file-badge {
  position: absolute;
  left: -6px;
  top: -8px;
}
.drawer-content .aula-spinner {
  width: 80px;
  height: 80px;
  position: absolute;
  right: 40px;
  top: -12px;
}
.spinHolder {
  clear: both;
}
.show-more {
  padding: 20px 0;
  text-align: center;
  button {
    float: none;
  }
}
.filetype {
  color: $color-primary-base-guardian;
  .theme-employee & {
    color: $color-primary-base-employee;
  }
}

.no-documents {
  margin: 20px 0;
  text-align: center;
  font-weight: bold;
}

.sort {
  &.asc::before,
  &.desc::before {
    margin-left: 3px !important;
    margin-right: -3px !important;
    color: unset !important;
  }
}
