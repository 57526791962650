







































































































.attachment-thumbnail {
  --object-fit: cover;
  --thumbnail-object-fit: cover;
  border: 5px solid var(--color-white);
  flex-grow: 1;
  width: 0;
}
