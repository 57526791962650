






































































.post-body {
  .unread-indicator {
    font-size: 32px;
    line-height: 16px;
    padding-right: 4px;
    color: var(--color-alert);
  }
}
.content-container {
  overflow: hidden;
  position: relative;
  max-height: 300px;

  .content /deep/iframe {
    max-width: 100%;
  }

  &[aria-expanded] {
    max-height: unset;
  }
  &:not([aria-expanded])::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 50px;
    background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 30%, rgba(255, 255, 255, 1) 100%);
  }
}
.content :link {
  text-decoration: underline;
}
.expand-icon {
  --font-size: 6px;
  padding-left: 4px;
}
