































































































































































.timepicker-container {
  position: relative;
  border-radius: 5px;
  display: flex;
  align-items: center;

  &:focus-within {
    outline: 1px solid var(--color-primary-dark);
  }
  /deep/ .vue__time-picker .dropdown ul li:not([disabled]).active {
    background-color: var(--active-color, var(--color-primary-darker));
    &:hover,
    &:focus {
      background-color: var(--active-color, var(--color-primary-darker));
    }
  }
  .time-picker {
    width: 100%;
    border-radius: 5px;
  }
}

.icon {
  --font-size: 1.25rem;

  position: absolute;
  right: 12px;
}

.disabled {
  color: var(--color-dark-grey);
}
