























































.group-member-popover-list {
  display: inline;

  &[aria-expanded] .group-member-popover:not(:last-child)::after {
    content: ',';
    padding-right: 4px;
  }
  .group-member-popover {
    display: inline;
  }
  .hidden-groups {
    color: var(--color-primary-dark);
    &::before {
      content: '+';
      padding: 0 3px;
    }
  }
}
