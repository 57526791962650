













































































































































































































































.post-comment {
  --indicator-size: 2px;
  --indicator-border-radius: 8px;
  --indicator-left: -32px;

  border-top: 1px solid var(--color-grey-base);
  padding: 12px 0;
  &[aria-expanded] {
    .main-comment {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 55px;
        left: 18px;
        height: calc(100% - 25px);
        border-left: var(--indicator-size) solid var(--color-grey-base);
      }
    }
    .load-more {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: 18px;
        height: 100%;
        border-left: var(--indicator-size) solid var(--color-grey-base);
      }
    }
  }
  .indicator {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 18px;
      left: var(--indicator-left);
      width: 15px;
      height: 20px;
      border-bottom-left-radius: var(--indicator-border-radius);
      border-left: var(--indicator-size) solid var(--color-grey-base);
      border-bottom: var(--indicator-size) solid var(--color-grey-base);
    }
    &:not(:last-child)::after {
      content: '';
      position: absolute;
      top: 0;
      left: var(--indicator-left);
      height: calc(100% + 30px);
      border-left: var(--indicator-size) solid var(--color-grey-base);
    }
  }
}
.reply-actions {
  padding-top: 5px;
}
.reply-icon {
  --font-size: 14px;
}
.comment-replies {
  margin-left: 50px;
  display: flex;
  flex-direction: column;
}
.reply-textarea {
  margin-top: 10px;
}
.load-more {
  --btn-link-color: var(--color-primary-dark);

  padding: 15px 0;
  display: flex;
  justify-content: center;
}
.dropdown-icon {
  font-size: 0.3rem;
  position: relative;
  top: -2px;
}
