












































































































































































































































.comment {
  display: flex;
  gap: 14px;
  .comment-content {
    flex-grow: 1;
    &[data-highlight] {
      background-color: var(--color-grey-base);
      padding: 5px 10px;
      border-radius: 4px;
    }
  }
  .content-body {
    overflow-wrap: anywhere;
    white-space: pre-wrap;
    padding-right: 40px;
  }
}
.content-header {
  display: flex;
}
.comment-avatar {
  --width: 40px;
  --height: 40px;
  --font-size: 0.8rem;
}
.date-time {
  color: var(--color-help-text);
  font-size: 14px;
}
.dropdown-select {
  margin-left: auto;
  height: 25px;
}
.reported-content,
.deleted-content {
  font-style: italic;
}
