




















































































.comment-textarea-container {
  --btn-link-color: var(--color-primary-dark);

  background-color: var(--color-grey-base);
  padding: 10px 24px;
  border-radius: 5px;
  .input-header {
    font-size: 14px;
  }
  .comment-textarea {
    height: 50px;
  }
}
