






















































































































































































































































































































































































































































































































































































































































































































































































@import '../assets/scss/core/variables.scss';
@import '../assets/scss/core/breakpoints.scss';

.has-help-box {
  display: flex;
}

.file-picker-col {
  position: unset;
}

.media-upload-preview-grid {
  margin-top: 10px;
  @include breakpoint-lg() {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(425px, 1fr));
  }
}

ol {
  list-style-type: none;
  padding-left: 0;

  li {
    line-height: 53px;
    border-radius: 7px;
    background-color: #fff;
  }

  .icon {
    font-size: 20px;
    color: $color-grey-lighter;
    padding: 16px;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;

    margin-top: 2px;
    margin-right: 20px;
    float: left;
  }
  .close {
    float: right;
    margin-top: 5px;
    cursor: pointer;
    margin-right: 10px;
  }

  i {
    margin-top: 15px;
  }
}

.theme-employee {
  ol li .icon {
    background-color: $color-primary-light-employee;
  }
}

.theme-guardian,
.theme-child {
  ol li .icon {
    background-color: $color-primary-light-guardian;
  }
}
