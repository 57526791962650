
















































































































































































































.post-comments {
  .actions {
    margin: 12px 24px;
  }
  .comment-list {
    margin: 0 24px;
    display: flex;
    flex-direction: column;
  }
}
.actions {
  display: flex;
  justify-content: space-between;
  .action-button {
    font-weight: bold;
  }
}
.input-container {
  --btn-link-color: var(--color-primary-dark);
  padding: 10px 24px;
  background-color: var(--color-grey-base);
}
.input-indicator {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 14px;
}
.load-more {
  --btn-link-color: var(--color-primary-dark);

  border-top: 1px solid var(--color-grey-base);
  margin: 0 24px;
  padding: 15px 0;
  display: flex;
  justify-content: center;
}
.dropdown-icon {
  font-size: 0.3rem;
  position: relative;
  top: -2px;
}
