
























.image-thumbnail {
  --object-fit: contain;
  --height: 100%;
  --width: 100%;
  --background-color: var(--color-grey-base);
}
