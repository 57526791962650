










































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.thumbnail {
  @include breakpoint-lg-down() {
    height: 100px;
    width: 100%;
  }
}

.half-width {
  display: flex;
  justify-content: center;
  .album-media-data {
    max-width: 45vw;
  }
}
.media-data-container {
  --dropdown-menu-min-width: 400px;
  @include breakpoint-sm-down() {
    --dropdown-menu-min-width: 60vw;
  }
  @include breakpoint-xs-down() {
    --dropdown-menu-min-width: 85vw;
  }
  & .album-media-data .media-item .media-thumbnail {
    .rotate-icon {
      cursor: pointer;
      position: absolute;
      top: 5px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      padding: 4px;
      background: white;
      opacity: 0.7;
      z-index: $media-icon-button-z-index;
      &.cw {
        right: 5px;
      }
      &.ccw {
        left: 5px;
      }
      i {
        color: $color-primary-darker;
      }
    }
  }
}

.media-item.download-link {
  cursor: pointer;

  .media-remove {
    bottom: auto;
  }
}
.media-thumbnail {
  display: flex;
}
