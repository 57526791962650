




































































































































































































































































































































































































































































































@import '../assets/scss/core/variables.scss';
@import '../assets/scss/core/breakpoints';

.modal-aula /deep/ .modal-header {
  display: none;
}

.arrow-down-outer {
  position: absolute;
  left: 10px;
  border-bottom: solid 13px $color-white;
  border-left: solid 13px transparent;
  border-right: solid 13px transparent;
}

.dropdown-menu[x-placement^='bottom'] {
  .arrow-down-outer {
    top: -11px;
  }
}

.dropdown-menu[x-placement^='top'] {
  .arrow-down-outer {
    bottom: -11px;
    transform: rotate(0.5turn);
  }
}

.dropup {
  i {
    font-size: 22px;
    margin-right: 10px;
    float: left;
    padding: 1px 0 1px 1px;

    &.icon-Aula_plus {
      font-size: 12px;
      padding: 5px;
      border-radius: 50%;
      color: var(--color-white);
      background-color: var(--color-primary-darker);
    }
  }
  a {
    .theme-employee & {
      color: $color-primary-darker-employee;
    }
    .theme-guardian & {
      color: $color-primary-darker-guardian;
    }
    .theme-child & {
      color: $color-primary-darker-child;
    }
    .theme-admin & {
      color: $color-primary-darker-admin;
    }
    padding-left: 1rem;
    padding-right: 4rem;
  }
  .dropdown-item {
    outline: none;
  }
}
