

















































































































.post-attachments {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 10px;

  .post-attachment-list {
    --background-color: var(--color-grey-base);
    --thumbnail-background-color: var(--color-white);
  }
}
.media-grid {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 12vh);
  grid-auto-flow: dense;

  .media-grid-item {
    border-radius: 6px;
    overflow: hidden;
  }

  &.grid-layout-1 .media-grid-item {
    --media-thumbnail-object-fit: contain;
    &:nth-child(1) {
      grid-row: span 4;
      grid-column: span 4;
    }
  }
  &.grid-layout-2 .media-grid-item {
    &:nth-child(1) {
      grid-row: span 4;
      grid-column: span 2;
    }
    &:nth-child(2) {
      grid-row: span 4;
      grid-column: span 2;
    }
  }
  &.grid-layout-3 .media-grid-item {
    &:nth-child(1) {
      grid-row: span 4;
      grid-column: span 2;
    }
    &:nth-child(2) {
      grid-row: span 2;
      grid-column: span 2;
    }
    &:nth-child(3) {
      grid-row: span 2;
      grid-column: span 2;
    }
  }
  &.grid-layout-4 .media-grid-item {
    &:nth-child(1) {
      grid-row: span 4;
      grid-column: span 2;
    }
    &:nth-child(2) {
      grid-row: span 2;
      grid-column: span 2;
    }
    &:nth-child(3),
    &:nth-child(4) {
      grid-row: span 2;
    }
  }
}
