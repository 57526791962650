















































.avatar-group-variables {
  --size: 16px;
  --outline-width: 2px;

  .avatar {
    --width: 40px;
    --height: 40px;
    --font-size: 12px;
  }
}

.avatar-group {
  display: grid;
  grid-auto-flow: column;
  direction: rtl;
  width: fit-content;

  > .avatar {
    outline: var(--outline-width) solid var(--color-white);
    box-shadow: 0 0 0 1px var(--color-white);
  }

  > .avatar:not(:last-child) {
    margin-left: calc(-1 * var(--size));
  }

  &[data-variant='light'] > .avatar {
    --background-color: var(--color-white);
    --color: var(--color-primary-dark);
    --border: 2px solid var(--color-primary-dark);
  }

  &:not([data-extra='0']) {
    > .avatar:last-child {
      pointer-events: none;
      background-color: var(--color-grey-dunkel);

      &::after {
        content: '+' attr(data-extra);
        position: absolute;
        inset: 0;
        background-color: var(--color-grey-dunkel);
        color: var(--color-primary-darker);
        display: grid;
        place-items: center;
        font-size: 16px;
        direction: ltr;
      }
    }

    &[data-small-text] > .avatar:last-child::after {
      font-size: 12px;
    }
  }
}
