










































































































































































@import '../../../shared/assets/scss/core/breakpoints.scss';

@include breakpoint-lg() {
  .header-button {
    color: var(--color-white);
  }
}
