




















































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.tool-link {
  padding: 0;
  z-index: $multi-button-mobile-container-z-index;
  &.in-group {
    z-index: $multi-button-mobile-in-group-container-z-index;
  }

  &.no-icon {
    .aula-circle-large:not(.dropup-menu) {
      display: none;
    }

    .tool-link-text {
      position: unset;
    }
  }
}
.multi-button-backdrop {
  z-index: $multi-button-mobile-backdrop-container-z-index;
  &.in-group {
    z-index: $multi-button-mobile-in-group-backdrop-container-z-index;
  }
}

.tool-link-text {
  position: absolute;
  right: 77px;
  top: 15px;
  width: 195px;
  font-size: 16px;
  color: $color-white;
  text-transform: uppercase;
  text-align: right;
}

.aula-circle-large .aula-circle-small .icon {
  top: 15px;

  &.actionIcon {
    top: 14px;
    font-size: 25px;
    margin-right: 0;
  }
}

.tool-link-white {
  .aula-circle-large,
  .aula-circle-small {
    background: $color-white;
    .icon {
      color: $color-darkblue;
      font-size: 25px;
      top: 10px;
    }
  }
}

.aula-circle-large {
  height: 55px;
  width: 55px;
}

.aula-circle-small {
  height: 45px;
  width: 45px;
  top: 5px;
  left: 5px;
}

.modal-aula-backdrop.show {
  opacity: 1;
}

img.icon {
  position: absolute;
  width: 38px;
  left: 8px;
  top: 10px !important;
}
