.document-content-wrapper {
  background-color: var(--color-white);
  display: flex;
  justify-content: center;
  border-radius: 5px;
  min-height: 50px;
  padding: 20px 10px;
}
.document-content{
  max-width: 49.6rem;
  flex-grow: 1;
  font-size: initial;
  color: initial; // Necessary to ensure color mathces edit-mode.

  /deep/caption {
    caption-side: initial;
    padding: unset;
    color: initial;
    text-align: center;
  }
}

.document-title {
  word-break: break-all;
}

