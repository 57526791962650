








































































































































































@import '../assets/scss/core/variables.scss';
@import '../assets/scss/core/breakpoints.scss';

.attachment-list-variables {
  --background-color: var(--color-white);
  --thumbnail-background-color: var(--color-grey-base);
}
.attachment-list {
  display: flex;
  flex-direction: column;
  gap: 6px;
  .album-media-data {
    padding: 6px;
    background-color: var(--background-color);
    .media-item {
      display: flex;
      flex-direction: row;
      .media-thumbnail {
        width: 36px;
        height: 36px;
        padding: 8px;
        border-radius: 6px;
        background-color: var(--thumbnail-background-color);
      }
    }
  }
}
.aula-spinner {
  height: 70px;
  width: 70px;
}
.media-data-container {
  cursor: pointer;
  .disabled {
    cursor: no-drop;
  }
}
