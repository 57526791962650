


































































































































































































.post-header {
  display: flex;
  gap: 10px;

  .post-metadata {
    flex-grow: 1;
    padding-left: 5px;
  }

  .metadata-container {
    font-size: 14px;
  }
}

.metadata-text {
  color: var(--color-help-text);

  &.datetime:first-letter {
    text-transform: uppercase;
  }
}

.shared-profiles {
  flex-shrink: 0;
}

.dropdown-select {
  --dropdown-select-padding: 5px;
}
