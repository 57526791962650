
































































































.post-variables {
  --layout-spacing: 12px 24px 24px 24px;
  --box-shadow: 0px 2px 4px 0px rgba(157, 157, 157, 0.5);
  --max-width: 60rem;
}
article.post {
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  overflow: hidden;
  position: static;
  &[data-unread] {
    box-shadow: var(--box-shadow), 0 0 0 2px var(--color-primary-dark) inset;
    background-color: var(--color-grey-base);

    /deep/.comment-textarea-container {
      background-color: var(--color-grey-dunkel);
      &:not(.reply-textarea) {
        margin: 2px;
      }
    }
    /deep/.metadata-text {
      font-weight: bold;
    }
    /deep/.post-comment {
      &:first-child {
        border-color: var(--color-grey-darker);
        border-width: 2px;
      }
      .main-comment:before,
      .indicator::before,
      .indicator::after {
        border-color: var(--color-grey-dunkel);
      }
    }
  }
}
.post-layout {
  padding: var(--layout-spacing);
}
.post-badges {
  min-height: 12px;
  width: min-content;
  overflow: hidden;
  display: flex;
  border-bottom-right-radius: 8px;
  .post-badge {
    color: var(--color-white);
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
    padding: 3px 10px;
    width: fit-content;
    &.planned,
    &.expired {
      background-color: var(--color-primary-darker);
    }
    &.important {
      background-color: var(--color-alert);
    }
  }
}
