





































































































































.group-member-popover {
  .group-name {
    &.disabled {
      color: var(--color-grey);
      opacity: 1;
    }
    &:not(.disabled) {
      color: var(--color-primary-dark);
    }
  }
}
.member-type {
  font-weight: 700;
  font-size: 15px;
  margin-top: 16px;
  color: var(--color-primary-darker);

  &:first-child {
    margin-top: 0;
  }
}
