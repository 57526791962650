


































.media-thumbnail-variables {
  --media-thumbnail-object-fit: cover;
}
.media-thumbnail {
  position: relative;
  .image-thumbnail {
    --object-fit: var(--media-thumbnail-object-fit);
  }
  .video-thumbnail {
    --thumbnail-object-fit: var(--media-thumbnail-object-fit);
  }
  .thumbnail-overlay {
    position: absolute;
    inset: 0;
    display: grid;
    background-color: rgba(0, 0, 0, 0.4);
    place-items: center;
    .overlay-text {
      color: var(--color-white);
      font-weight: bold;
      font-size: 20px;
    }
  }
}
