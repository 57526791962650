





















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/documents/_documentDetails.scss';

.read-only-mode {
  background-color: transparent !important;
  border: none !important;
  padding: 0px !important;
  margin-top: -10px;
  color: $color-darkblue !important;
}
.time-info {
  display: flex;
  justify-content: flex-end;
  font-size: 13px;
}
.can-edit-title {
  margin: 10px 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.message-attachment {
  display: grid;
  grid-template-columns: 36px 1fr;
  grid-template-rows: 36px;
  align-items: center;
  gap: 8px;
  padding: 6px;
  margin-top: 16px;
  background-color: var(--color-white);
  border-radius: 6px;
  .thumbnail {
    background-color: var(--color-grey-base);
    border-radius: 6px;
    height: 36px;
    display: grid;
    place-items: center;
    .file-icon {
      --font-size: 1.25rem;
    }
  }
}
